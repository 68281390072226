import { Box, Container, Button, Paper } from "@mui/material";
import React, { useEffect }  from "react";
import { useLocation, useNavigate } from "react-router";
import logo from "../../assets/logo.svg";
import { Logger } from "logging-library";
import FileViewer from "react-file-viewer";

const Compare = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const imgData = localStorage.getItem("imgData");
  const parseImgData = JSON.parse(imgData);
  const fileType = parseImgData[1];

  useEffect(() => {
    if(!(location.state && location.state.downloadUrl)){
      navigate('/')
    }
  }, [])
  const onError = (e) => {
    Logger.logError(e, "error in file-viewer");
  };
  function downloadFile(url,fileName) {
   
    fetch(url).then(response => {
      response.blob().then(blob => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = fileName
          alink.click();
      })
  })
  }
  const changeImage = () => {
    navigate("/");
  };
  const downloadImage = () => {
    downloadFile(location.state.downloadUrl,location.state.fileName);
    navigate("/");
  };


  return (
    <Box p="2%">
      <Container>
        {/*  ======== first child ================== */}
       <Box sx={{display:"flex",alignItems:"center",justifyContent:"space-between",width:"90%",m:"auto"}}> 
       <Box >
          <img
            src={logo}
            alt=""
            style={{ maxWidth: "70px", maxHeight: "63px" }}
          />
        </Box>
        <Box
          sx={{
            display:"flex",flexDirection:"column",justifyContent:"center"
          }}
        >
          <Button
            variant="contained"
            href="https://www.habilelabs.io/contact-us"
            sx={{
              background: "#2EAEE9",
              ":hover": {
                background: "#2EAEE9 ",
              },fontSize:  { xs: "0.600rem", md: "0.875rem" }
            }}
          >
            Contact Us
          </Button>
        </Box>

       </Box>
        {/* ============second child ================== */}

        <Paper
          elevation={3}
          border="1px solid black"
          sx={{
            display: "flex",
            maxWidth: "90%",
            m: { xs: "30px auto", md: "30px auto", lg: "30px auto" },
            flexDirection: "column",
            height: "475px",
          }}
        >
          {/* ===========second child left part ============== */}
          <Box
            sx={{
              flex: "1",
              display: "flex",
              flexDirection: { xs: "column", md: "row", lg: "row" },
              alignItems: "stretch",
              gap: { xs: "20px", md: "0px" },
            }}
          >
            <Box width="100%" sx={{ background: "#F2FBFF" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "50px",
                  flexDirection: "column",
                  width: "100%",
                  height: "82%",
                  p: {xs:"50px",md:"20px"},
                }}
              >
                <Box>
                  <Box
                    fontSize="30px"
                    width="120px"
                    height="40px"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      textAlign: "center",
                      borderRadius: "4px",
                      background: "#E1F0F9",
                      color: "#02174E",
                      boxShadow: "#02174E -5px 5px",
                    }}
                  >
                    Before
                  </Box>
                </Box>

                <Box
                  minWidth={`${fileType === "pdf" ? "60%" : "200px"}`}
                  alignItems={`${fileType === "pdf" ? "none" : "center"}`}
                  height="300px"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  {fileType === "pdf" ? (
                    <FileViewer
                      fileType={fileType}
                      filePath={parseImgData[0]}
                      onError={onError}
                    />
                  ) : (
                    <img src={parseImgData[0]} width="58%" alt="" />
                  )}
                </Box>
              </Box>
            </Box>
            {/* ===========second child right part ============== */}

            <Box
              width="100%"
              sx={{ background: "#E8ECEE", borderRadius: "5px" }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  gap: "60px",
                  flexDirection: "column",
                  width: "100%",
                  height: "100%",
                  p: "20px",
                }}
              >
                <Box>
                  <Box
                    fontSize="30px"
                    width="120px"
                    height="40px"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      textAlign: "center",
                      borderRadius: "4px",
                      background: "#E1F0F9",
                      color: "#02174E",
                      boxShadow: "#02174E -5px 5px",
                    }}
                  >
                    After
                  </Box>
                </Box>

                <Box
                  minWidth={`${fileType === "pdf" ? "60%" : "200px"}`}
                  alignItems={`${fileType === "pdf" ? "none" : "center"}`}
                  height="300px"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  {fileType === "pdf" ? (
                    <FileViewer
                      fileType={fileType}
                      filePath={location.state.downloadUrl}
                      onError={onError}
                    />
                  ) : (
                    <img src={location.state && location.state.downloadUrl} width="58%" alt="" />
                  )}
                </Box>
                <Box display="flex" alignItems="center" gap="10px">
                  <Button
                    variant="outlined"
                    sx={{
                      color: "#02174E",
                      ":hover": { background: "#2EAEE9" },
                  
                      fontSize: { xs: "0.600rem", md: "0.875rem" },
                    }}
                    onClick={changeImage}
                  >
                    Change Image
                  </Button>

                  <a  href={downloadFile} download>
                    <Button
                      variant="contained"
                      sx={{
                        color: "#02174E",
                        background: "#2EAEE9",
                        ":hover": {
                          background: "#2EAEE9 ",
                        },
                        fontSize: { xs: "0.600rem", md: "0.875rem" },
                      }}
                      onClick={downloadImage}
                    >
                      Download
                    </Button>
                  </a>
                </Box>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default Compare;
