import { Box, Button, Typography } from "@mui/material";
import React, { useRef, useCallback } from "react";
import Logo from "../../assets/logo.svg";
import Group from "../../assets/Group 4771.png";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { useNavigate } from "react-router-dom";
import useStyles from "../../assets/css/Home";
import { useDropzone } from "react-dropzone";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Home = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const images = [];
  const handleClick = () => {
    inputRef.current.click();
  };
  const checkFileData = (fileData) => {
    const fileType = fileData.name.split(".").pop().toLowerCase();

    const checkFileType = ["png", "jpg", "jpeg", "pdf"];

    if (!checkFileType.find((file) => fileType === file)) {
      return toast(
        "File does not support. You must use .png or .jpg or.jpeg or .pdf "
      );
    }
    if (fileData.size > 50 * 1024 * 1024) {
      toast("Please upload a file smaller than 50 MB");
      return false;
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(fileData);
      reader.addEventListener("load", () => {
        images.push(reader.result);
        images.push(fileType);
        images.push(fileData.name);
        images.push(fileData.size / (1024 * 1024 * 50));
        localStorage.setItem("imgData", JSON.stringify(images));
        navigate("/mask-aadhaar", {
          state: {
            fileData,
          },
        });
      });
    }
  };
  const blogDataChange = (event) => {
    const fileData = event.target.files[0];
    checkFileData(fileData);
  };
  const onDrop = useCallback((acceptedFiles) => {
    checkFileData(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noClick: true,
  });

  return (
    <Box className={classes.parent} flexDirection={{ xs: "column", lg: "row" }}>
      {/* ========================= left part  ======================== */}
      <Box className={classes.leftParent}>
        <Box className={classes.common}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: {
                xs: "space-between",
                md: "center",
              },
              padding: {
                xs: "6% 7%",
                sm: "0 0%",
              },
            }}
          >
            <Box
              sx={{
                width: {
                  xs: "70px",
                  md: "107px",
                },
                height: {
                  xs: "63px",
                  md: "107px",
                },
              }}
            >
              <img src={Logo} alt="Logo" width="100%" />
            </Box>
            <Box
              sx={{
                display: {
                  sx: "inline-block",
                  md: "none",
                },
              }}
            >
              <Button
                variant="contained"
                href="https://www.habilelabs.io/contact-us"
                sx={{
                  background: "#2EAEE9",
                  ":hover": {
                    background: "#2EAEE9 ",
                  },
                }}
              >
                Contact Us
              </Button>
            </Box>
          </Box>

          <Box
            sx={{
              padding: {
                xs: "0 6%",
                sm: "0 0%",
              },
            }}
          >
            <Typography
              variant="h2"
              fontWeight={500}
              color="#FFFFFF"
              textAlign="center"
            >
              Aadhaar Masking API Demo
            </Typography>
          </Box>

          <Box>
            <img src={Group} alt="Logo" />
          </Box>
        </Box>
      </Box>
      {/* =========== right part ====================================  */}
      <Box sx={{ flex: 1 }} className={classes.rightParent}>
        <Box
          className={classes.button}
          sx={{
            display: {
              xs: "none",
              md: "inline-block",
            },
          }}
        >
          <Button
            variant="contained"
            href="https://www.habilelabs.io/contact-us"
            sx={{
              background: "#2EAEE9",
              ":hover": {
                background: "#2EAEE9 ",
              },
            }}
          >
            Contact Us
          </Button>
        </Box>

        <Box className={classes.common}>
          <Box>
            <Typography variant="h4" color="black" textAlign="center">
              Select an Aadhaar file to mask
            </Typography>

            <Typography variant="body1" color="gray" textAlign="center">
              Accepted file types: .png, .jpg, .jpeg, .pdf (Single Page)
            </Typography>
          </Box>

          <div {...getRootProps({ className: "dropzone" })}>
            <Box className={classes.browseFile} p={5} borderRadius={2}>
              <Box>
                <FileUploadOutlinedIcon style={{ color: "#2EAEE9" }} />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  gap="10px"
                >
                  <input {...getInputProps()} onChange={blogDataChange} />
                  <Typography
                    variant="h5"
                    sx={{ color: "white" }}
                    textAlign="center"
                  >
                    Drag & Drop files here...
                  </Typography>
                  <Box>
                    <Typography
                      variant="body1"
                      sx={{ color: "#D6D6D6" }}
                      textAlign="center"
                    >
                      Or
                    </Typography>
                  </Box>

                  <Box>
                    <input
                      style={{ display: "none" }}
                      ref={inputRef}
                      type="file"
                      name="path"
                      onChange={blogDataChange}
                    />
                    <Button variant="contained" onClick={handleClick}>
                      Browse Files
                    </Button>
                  </Box>

                  <Box>
                    <Typography
                      variant="body1"
                      sx={{ color: "#D6D6D6" }}
                      textAlign="center"
                    >
                      Maximum Upload Size 50 MB
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </div>
          <ToastContainer />
        </Box>
      </Box>
    </Box>
  );
};

export default Home;
