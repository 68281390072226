import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    common: {
        flex: 1, display: "flex", flexDirection: "column"
    },
    secondChild: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "20px",
        background: "#F2FBFF",
        boxShadow: " 0px 3px 6px #00000029",
        minHeight: "maxContent", position: "absolute",
        transform: `translate(-50%,50%)`,
        left: "50%", 
        width: "90%",
        height: "100%", justifyContent: "space-around",
        bottom: "47%"
    },
    buttonParent: {
        gap: "10px", display: "flex", flexDirection: "row"
    },
    imageDiv:{
        display:"flex",flexDirection:"column",justifyContent:"center"
    }



}))


export default useStyles;