import React, { useState } from "react";
import { Logger } from "logging-library";
import FileViewer from "react-file-viewer";
import {
  Box,
  Button,
  Container,
  Dialog,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/logo.svg";
import axios from "axios";
import useStyles from "../../assets/css/MaskAadhaar";
import FormData from "form-data";
import Form from "../Form/Form";
const maskingAPI = process.env.REACT_APP_BACKEND_URL || "https://api-gate.finhub.habilelabs.io/v1";

const MaskAadhaar = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const ImgData = localStorage.getItem("imgData");
  const parseData = JSON.parse(ImgData);
  const fileType = parseData[1];
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [fileData, setFileData] = useState(null);
  const changeImage = () => {
    navigate("/");
  };
  const onError = (e) => {
    Logger.logError(e, "error in file-viewer");
  };
  const openFormField = () =>{
    setIsFormOpen(true);
  }
  const apiData = async () => {
    if (!location.state || !location.state.fileData) {
        alert("No file data available.");
        return;
    }

    const data = new FormData();
    data.append("file", location.state.fileData);
    let maskingEndPoint =  maskingAPI + '/aadhaar-masking';
    try {
        const response = await axios.post(maskingEndPoint, data, {
            maxBodyLength: Infinity,
            headers:{
              "api_key": "4177075065",
              "api_secret": "53cf1a7f4aba6c0cfd3fd75ee7155c98aac8e677c2b2db67c98be1753ac90ddd",
            }
        });

        if (response.data.status === 200) {
            navigate("/compare", {
                state: response.data,
            });
        } else {
            alert("Please choose a file with one of the following extensions: .png, .jpg, .jpeg, .pdf");
        }
    } catch (error) {
        console.error("Error occurred while sending the request:", error);
        alert("An error occurred while sending the request.");
    } finally {
        // Clear file data from state after request is completed
        clearFileData();
    }
};

const clearFileData = () => {
  // Clear file data from state
  setFileData(null);
};


  return (
    <Box className={classes.common} p="2%">
      <Container
        maxWidth="lg"
        sx={{ flex: 1, display: "flex", flexDirection: "column" }}
      >
        <Box sx={{ maxWidth: "80%", m: "auto 5%" }}>
          <img
            src={logo}
            alt=""
            style={{ maxWidth: "70px", maxHeight: "63px" }}
          />
        </Box>
        <Box flexGrow="1" position="relative">
          <Box className={classes.secondChild} p="25px 0" mx="auto">
            <Box padding="20px">
              <Typography variant="h4" color="#11111" textAlign="center">
                Mask your aadhaar with just one click!
              </Typography>

              <Typography variant="body1" color="grey" textAlign="center">
                Accepted file types: .png, .jpg, .jpeg, .pdf (Single Page)
              </Typography>
            </Box>

            <Box
              minWidth={`${fileType === "pdf" ? "25%" : "40%"}`}
              alignItems={`${fileType === "pdf" ? "none" : "center"}`}
              height="300px"
              className={classes.imageDiv}
            >
              {fileType === "pdf" ? (
                <FileViewer
                  fileType={fileType}
                  filePath={parseData[0]}
                  onError={onError}
                />
              ) : (
                <img
                  src={parseData[0]}
                  width="100%"
                  height="100%"
                  style={{ objectFit: "contain" }}
                  alt=""
                />
              )}
            </Box>

            <Box className={classes.buttonParent}>
              <Button
                variant="outlined"
                sx={{
                  color: "#02174E",
                  ":hover": { background: "#2EAEE9" },
                  fontSize: { xs: "10px", md: "14px" },
                }}
                onClick={changeImage}
              >
                Change Image
              </Button>
              <Button
                variant="contained"
                sx={{
                  color: "#02174E",
                  background: "#2EAEE9",
                  ":hover": {
                    background: "#2EAEE9 ",
                  },
                  fontSize: { xs: "10px", md: "14px" },
                }}
                onClick={openFormField}
              >
                Mask aadhaar
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "80%"} }}
        maxWidth="xs"
        open={isFormOpen}
      >
        <Form
          handleClose={() => {
            setIsFormOpen(false);
          }}
          apiData={apiData}
        />
      </Dialog>
    </Box>
  );
};

export default MaskAadhaar;
