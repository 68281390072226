import React from "react";
import { Box } from "@mui/material";
import Home from "./Components/Home/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import BackImg from "./assets/MaskGroup.svg";
import MaskAadhaar from "./Components/MaskAadhaar/MaskAadhaar";
import Compare from "./Components/Compare/Compare";

function App() {
  return (
    <BrowserRouter>
      <Box
        display="flex"
        flexDirection="column "
        sx={{
          backgroundImage: `url(${BackImg})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          minHeight: "100vh"
        }}
      >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/mask-aadhaar" element={<MaskAadhaar />} />
          <Route path="/compare" element={<Compare />} />
        </Routes>
      </Box>
    </BrowserRouter>
  );
}

export default App;
