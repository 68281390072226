import {
  Backdrop,
  Box,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import "react-toastify/dist/ReactToastify.css";
import useStyles from "../../assets/css/Form";
import { useForm, Controller } from "react-hook-form";
const leadAPI = "https://backend.habilelabs.io/api/lead-aadhars";

const Form = (props) => {
  const classes = useStyles();
  const { handleClose, apiData } = props;
  const [loader, setLoader] = useState(false);
  const {
    control,
    getValues,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const res = await fetch(
        leadAPI,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: {
              Name: data.name,
              Email: data.Email,
              Phone_Number: data.Phone_Number,
            },
          }),
        }
      );
      if (res) {
        setLoader(true);
        typeof apiData === "function" && apiData();
      }
    } catch (error) {
      console.log("error :", error);
    }
  };

  const inputValidation = (event) => {

    let validatedString = ""
    if (event.target.name === "Phone_Number") {
      validatedString = event.target.value.replace(/[^0-9]/gi, "");
      if(event.target.value.length <= 10) setValue(event.target.name, validatedString)
    }
  };
  return (
    <>
      <Box className={classes.parent}>
        <Box className={classes.parentChild} p="35px 25px">
          <Typography variant="h5">Fill your details and continue</Typography>
          <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
            <Box className={classes.controller}>
              <Controller
                render={({ field }) => {
                  return (
                    <TextField
                      {...field}
                      className={classes.input}
                      label="Name*"
                      type="text"
                      placeholder="Name"
                      error={parseInt(errors.name)}
                      helperText={errors.name && errors.name.message}
                    />
                  );
                }}
                rules={{
                  required: "Name is required",
                  pattern: {
                    value: /^[A-Za-z\s]+$/,
                    message: "Name is not in correct format",
                  },
                }}
                control={control}
                name="name"
                value={getValues("name")}
              />
            </Box>

            <Box className={classes.controller}>
              <Controller
                className={classes.input}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className={classes.input}
                    label="Email*"
                    type="email"
                    placeholder="Email"
                    error={parseInt(errors.email)}
                    helperText={errors.email && errors.email.message}/>
                )}
                rules={{
                  required: "Email is required",
                  pattern: {
                    value:
                      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Email is not in correct format",
                  },
                }}
                control={control}
                name="Email"

              />
            </Box>

            <Box className={classes.controller}>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    className={classes.input}
                    label="Phone Number*"
                    type="tel"
                    placeholder="Phone Number"
                    error={parseInt(errors.Phone_Number)}
                    helperText={errors.Phone_Number && errors.Phone_Number.message}
                    onChange={inputValidation}
                  />
                )}
                rules={{
                  required: "Phone Number is required",
                  pattern: {
                    value: /^[6-9]\d{9}$/,
                    message: "Invalid Phone Number",
                  },
                }}
                control={control}
                name="Phone_Number"
              />
            </Box>

            <button className={classes.button}>Continue</button>
          </form>
        </Box>
      </Box>
      <AiOutlineClose
        onClick={handleClose}
        cursor="pointer"
        className={classes.cross}
      />
      <Backdrop open={loader}>
        <CircularProgress />
      </Backdrop>
    </>
  );
};

export default Form;
