import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  parent: {
    width: "100%",
    height: "100%",
    background: "#F2FBFF",
    borderRadius: "4px",
  },
  parentChild: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "1rem",
    textAlign: "center",
  },
  form: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "1px 25px",
    gap: "20px",
  },

  button: {
    padding: "10px 8px",
    font: "bold",
    borderRadius: "4px",
    color: "#02174E",
    background: "#2EAEE9",
    fontSize: "15px",
    cursor: "pointer",
  },
  cross: {
    position: "absolute",
    right: "20px",
    top: "10px",
    fontSize: "20px",
    cursor: "pointer",
  },
  controller: {
    height: "70px",
  },
  input: {
    width: "100%",
    "& .MuiFormHelperText-root": {
      color: 'red',
    },  
  },
  error: {
    padding: "2px",
    margin: "2px",
    color: "red",
    textAlign: "left",
    fontSize:"12px"
  }
}));

export default useStyles;
