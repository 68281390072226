import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    common: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap:"50px"
    },
    parent: {
        height: "100%",
        flex: "1",
        display: "flex",
        flexDirection: { xs: "column", lg: "row" }
    },
    leftParent: {
        flex: 1, backgroundColor: "#02174e",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    browseFile: {
        border: "1px solid green",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        flexDirection: "column",
        gap: "8px",
        backgroundColor: "#02174e"
    },
    rightParent:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        padding:"30px",
        position:"relative",
        flexDirection:"column"
    },
    button:{
        textAlign: "end",
        position:"absolute",
        top:"3%",
        right:"2%",
        width: "100%",
        height: "150px",
   
    }


}))


export default useStyles;